<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
     
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mx-auto mt-0 ml-0 pa-1">
            
                        
          <v-col offset="0" cols="12" xl="2" lg="4" md="4" sm="4" xs="6">
             <v-text-field
                transition="fab-transition"
                ref="status"
               
                v-model="dados.descricao"
                name="nome"
                label="Nome"                
                value
                filled
                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
             <v-col cols="12" xl="4" lg="2" md="2" sm="3" xs="2">
              <v-autocomplete
                v-model="dados.flg_identificacao"
                ref="identificacao"
                :items="listaIndent"
                name="identificacao"
                label="Identificação"
                item-value="valor"
                item-text="descricao"
                filled
             
                :name="`identificacao_${Math.random()}`"
                hide-details  
                
              ></v-autocomplete>
            </v-col>
               <v-col cols="12" xl="2" lg="2" md="2" sm="3" xs="3">
              <v-autocomplete
                v-model="dados.ordem_rel"
                ref="ordem"
                name="ordem"
                :items="contador"
                label="Ordem Res.Operacional"
                
                filled
                clearable
                :name="`ordem_${Math.random()}`"
                hide-details
                @keyup.enter="mudaCampo('status')"
              ></v-autocomplete>
            </v-col>
          </v-row>
            <v-row class="mx-auto mt-0 ml-0 pa-5">
            <v-col class="text-left" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                 <p class="font-weight-regular text-caption">Tipo de Status para o Resultado Operacional</p>
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                   <v-radio-group
                   v-model="dados.flg_tipo_op"                  
                  row
                  hide-details=""
                >
                  <v-radio
                    label="Vendas"
                    value="V"
                    
                  ></v-radio>
                  <v-radio
                    label="Compras"
                    value="C"
                    
                  ></v-radio>
                  <v-radio
                    label="Despesas"
                    value="D"
                    
                  ></v-radio>
                  <v-radio
                    label="Investimentos"
                    value="I"
                    
                  ></v-radio>
                  <v-radio
                    label="Outros Créditos"
                    value="OC"
                    
                  ></v-radio>
                      <v-radio
                    label="Outros Débitos"
                    value="OD"
                    
                  ></v-radio>
                </v-radio-group>
                
          
            </v-col>
            </v-row>
              <v-row class="mx-auto mt-0 ml-0 pa-5">
             <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                @click="salvar()"
                color="success"
              >
                  <v-icon left>
                 mdi-content-save-outline
                </v-icon>
                 Salvar
              </v-btn>
             </v-col>
             <v-col v-show="editar" cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
               <v-btn
                small
                @click="cancelEdit()"
                color="warning"
              >
                  
                 Cancelar
              </v-btn>
            </v-col>
          </v-row>
          
      
    </v-card>
    <v-card class="mt-5">
     <v-row  no-gutters>
      <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
      
    
    
      <v-data-table
        :headers="headers"
        :items="listaStatus"
        :loading="false"
        :search="search"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
        <template v-slot:item.chk_ativo="{ item }">
        <v-switch
                dense
                flat
                inset
                hide-details=""
                v-model="item.chk_ativo"
                 @change="mudarStatus(item.id_grupo_dep,item.chk_ativo)"                
              ></v-switch>
      </template>
       <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <!--<v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>-->
            </template>
       
      </v-data-table>  
   
    
     </v-col>
    </v-row>
    </v-card>
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
  
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    
    this.buscaStatus();
     this.mudaCampo("codigo");
  },
  data: () => ({
    editar:false,
    mostraMsg:false,
    textSnack:"",
    corMsg:"",
    search: "",
    contador:[1,2,3,4,5,6,8,9,10],
    dados:{     
     
      descricao:"",      
      id_fin_status:null,     
      flg_tipo_op:null
    },
    listaIndent:[{
      valor:'D',
      descricao:"Débito"
    },    
    {
      valor:'C',
      descricao:"Crédito"
    }],
    items: [],
    
    itemsBread: [
      {
        text: "Cadastros",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Status-Grupo Financeiro",
        disabled: false,
        href: "/dashboard/grupo_financeiro",
      },
    ],
    dialog: false,
    grupos:[],
    
    listaStatus:[],
       headers: [
    
      {
        text: "Descrição",
        value: "descricao",
      },
    
      { text: "Ações", value: "actions", sortable: false },
    ],
    selDel:"",
    selEdit:"",
    dialogDelete:false,
  }),
  methods:{

     mudarStatus(id,status){

        axios
        .post(
          `/fin/grupo/status`,
          { id: id,chk_status:status, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
        
          if(status){
            this.textSnack = "Ativado com sucesso !";
            this.corMsg = "success";
          }else{
            this.textSnack = "Desativado com sucesso!";
            this.corMsg = "error";
          }
          
        
          this.mostraMsg = true;
          
          //this.grupos = response.data;
        });
    },
     editItem(item){
         this.dados = item;
         this.editar = true;
     },
     cancelEdit(){
       this.editar = false;
       this.limpaCampos()
       this.buscaStatus();

     },
     deleteItemConfirm() {
    //  this.listaLancamentos.splice(this.editedIndex, 1);
      this.dialogDelete = false;
      axios
        .post(
          `/fin/status/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          this.buscaGrupos();
          this.textSnack = "Exclusão feito com sucesso!";
        
          this.mostraMsg = true;
          this.corMsg = "success";
          //this.grupos = response.data;
        });
    },
    limpaCampos(){
      this.dados.flg_identificacao =""
      this.dados.codigo =""
      this.dados.descricao = ""
      this.dados.ordem_rel = ""
      this.dados.flg_tipo_op = null
      this.dados.id_fin_status = null
    
    },
    deleteItem(item) {
      
      this.selDel = item.id;
      this.dialogDelete = true;
    },
     mudaCampo(campo) {
     // eval("this.$refs." + campo + ".focus()");
     },
     validacao() {

      if (this.dados.flg_identificacao == "") {
        this.textSnack = "Informe a Identificacao";
        this.mudaCampo("codigo");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
      if (this.dados.descricao == "") {
        this.textSnack = "Preencha a descrição";
        this.mudaCampo("descricao");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       if (this.dados.flg_tipo_op == null) {
        this.textSnack = "Selecione um Status";
        this.mudaCampo("status");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       
      return true
     },
   
    buscaGrupos() {
      axios
        .post(
          `/fin/grupo/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
    buscaStatus() {
      axios
        .post(
          `/fin/status/lista_simples`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.listaStatus = response.data;
        });
    },
    salvar(){

        if (!this.validacao()) {
          
          return false;
        }
        axios
        .post(
          `/fin/status`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: this.dados,
          }
          
        )
        .then((response) => {
        
        
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";
         
          this.mudaCampo("descricao");
          this.limpaCampos();        
         
          this.buscaStatus();
        })
        .catch((e) => {
          this.loading = false;
          
          if (!Array.isArray(e.response.data)) {
          
            let itens = [];
            for (var prop in e.response.data) {
              itens.push(e.response.data[prop][0]);
            }
         

            this.textSnack = itens.join("");
            this.mostraMsg = true;
            this.corMsg = "error";
          } else {
         
            this.textSnack = e.response.data;

            this.mostraMsg = true;
            this.corMsg = "error";
          }
        });


    }
  }
};
</script>
